import "./userView.css";
import { useEffect, useState } from "react";
import { Table, Form, ServiceRepository } from "construct-react";
import AuthView from "../auth/authView";
import { tableDescriptor, formDescriptor } from "./userDesc";

function UsersView() {
    const service = ServiceRepository.getInstance().getService("user");
    const [showForm, setShowForm] = useState(false);

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    });

    const showEditForm = (entity) => {
        setShowForm(!showForm);
        setSelectedEntity(entity);
    }

    const onFormSubmit = (res) => {
        setIsLoaded(false);
    }

    const onFormClose = (res) => {
        setShowForm(false);
        setSelectedEntity(null);
    }

    let listeners = [];
    listeners.push((id, res, items) => {
        if (id === "newUser")
            showEditForm({});

        if (id === "editUser")
            showEditForm(items[0]);
    });

    const tableDesc = tableDescriptor();

    return (
        <>
            <AuthView>
                <div className="user-list-view">
                    <div className="user-table-container">
                        {isLoaded && <Table
                            id="user"
                            load={(args) => service.fetchAll()}
                            descriptor={tableDesc}
                            changeListeners={listeners}  />}
                    </div>
                    {showForm &&
                        <div className="user-form-container">
                            <Form
                                entity={selectedEntity}
                                serviceId="user"
                                descriptor={formDescriptor}
                                submitCallback={onFormSubmit}
                                closeCallback={onFormClose} />
                        </div>}
                </div>
            </AuthView>
        </>
    );

} export default UsersView;