import { ServiceRepository } from "construct-react";

export const tableDescriptor = () => {
    return ({
        columns: [
            {
                id: "name",
                label: "Name"
            },
            {
                id: "description",
                label: "Description"
            }
        ],
        tableOptions: {
            style: "buttons",
            options: [
                {
                    style: "icon",
                    icon: "icons/add.svg",
                    label: "Add",
                    onSelect:
                    {
                        "id": "newApp",
                        "do": async (items) => {

                        }
                    }
                }
            ]
        },
        rowOptions: {
            style: "row",
            options: [
                {
                    style: "icon",
                    icon: "icons/edit.svg",
                    label: "Edit",
                    onSelect:
                    {
                        "id": "editApp",
                        "do": async (items) => {

                        }
                    }
                },
                {
                    style: "icon",
                    icon: "icons/delete.svg",
                    label: "Delete",
                    onSelect:
                    {
                        "id": "defaultDelete",
                        "do": async (items) =>
                            ServiceRepository.getInstance().getService("app").delete([items[0].id])
                    }
                },
            ]
        }
    });
}

export const formDescriptor = (appId) => {
    return ({
        fieldDescriptors: [
            {
                id: "id",
                label: "Id",
                type: "text-input",
                rules: {}
            },
            {
                id: "name",
                label: "Name",
                type: "text-input",
                rules: { required: true }
            },
            {
                id: "description",
                label: "Description",
                type: "text-input",
                rules: { required: true }
            },
            {
                id: "clients",
                label: "Clients",
                type: "multi-combo-input",
                optionProvider: (setOptions) => {
                    return ServiceRepository
                        .getInstance()
                        .getService("client")
                        .fetchAvailable(appId).then(res => {
                            res.json().then(json => {
                                setOptions(json);
                            });

                        }).catch(err => setOptions([]));
                },
                valueField: "id",
                optionDisplay: (option) => option.name
            }
        ]
    })
}
