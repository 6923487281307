import React from "react";
import {Navigate} from "react-router-dom";
import cookies from 'js-cookie';
import AuthService from "../service/AuthService";
import AuthContext from "../context/AuthContext";

export default class LogoutView extends React.Component {

    #domain = process.env.REACT_APP_DOMAIN;
    #redirectUri = null;

    constructor() {
        super();

        this.#redirectUri = window.location.href.replace('logout', '');
    }

    render() {
        const token = cookies.get('prism_token', {domain: this.#domain});
        const service = new AuthService();
        //TODO: Handle logging for negative and positive cases
        service.logout(token,
            res => {
                AuthContext.dispose();
                window.location.href = this.#redirectUri;
            },
            err => {
            });
        return (<Navigate to="/"/>);
    }
}