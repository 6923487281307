import "./appView.css";
import { useEffect, useState } from "react";
import { Form, Table, ServiceRepository } from 'construct-react';
import AuthView from "../auth/authView";
import { tableDescriptor, formDescriptor } from "./appDesc";

function AppView() {
    const service = ServiceRepository.getInstance().getService("app");
    const [showForm, setShowForm] = useState(false);

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    });

    const showEditForm = (entity) => {
        setShowForm(!showForm);
        setSelectedEntity(entity);
    }

    const onFormSubmit = (res) => {
        setIsLoaded(false);
    }

    const onFormClose = (res) => {
        setShowForm(false);
        setSelectedEntity(null);
    }

    let listeners = [];
    listeners.push((id, res, items) => {
        if (id === "newApp")
            showEditForm({});

        if (id === "editApp")
            showEditForm(items[0]);
    });

    const tableDesc = tableDescriptor();

    const table = () => (
        <div className="app-table-container">
            {isLoaded && <Table
                id="app"
                load={(args) => service.fetchAll()}
                descriptor={tableDesc}
                changeListeners={listeners} />}
        </div>);

    const form = () => (
        <div className="app-form-container">
            <Form
                label=""
                entity={selectedEntity}
                serviceId="app"
                descriptor={formDescriptor(selectedEntity.id)}
                submitCallback={onFormSubmit}
                closeCallback={onFormClose} />
        </div>
    );


    return (
        <>
            <AuthView>
                <div className="app-list-view">
                    {table()}
                    {showForm && form()}
                </div>
            </AuthView>
        </>
    );
} export default AppView;