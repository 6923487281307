import {Link} from 'react-router-dom';
import './navigation.css';
import AuthContext from '../../context/AuthContext';

import {SliderInput, Dropdown, AppContext} from 'construct-react';

function Navigation() {

    const fullname = AuthContext.getInstance().getFullname();
    const currentUrlPars = window.location.href.split("/");
    const redirectUri = currentUrlPars[0] + "/" + currentUrlPars[1] + "/" + currentUrlPars[2];

    function logoutClickHandler(e) {
        AuthContext.getInstance().removeToken();
        window.location.href = redirectUri;
    }

    return (
        <>
            <div className='nav-bar'>
                <div className='nav-bar-brand'>
                    <img
                        alt=""
                        src="logo.png"
                        className="nav-bar-brand-icon"/>
                    <div className='nav-bar-brand-title'>Prism</div>
                </div>
                <div className='nav-links'>
                    <Link className="nav-link" to="/sessions">Sessions</Link>
                    <Link className="nav-link" to="/apps">Apps</Link>
                    <Link className="nav-link" to="/clients">Clients</Link>
                    <Link className="nav-link" to="/users">Users</Link>
                </div>
                <div className='nav-bar-user-container'>
                    <Dropdown label={fullname}>
                        <div className="nav-dropdown-item nav-bar-style-variant-container">
                            <SliderInput
                                className="theme-variant-switch"
                                value={AppContext.getInstance().isDarkVariant()}
                                onValueChange={(value) => {
                                    AppContext.getInstance().setThemeVariant(value ? "dark" : "light");
                                }}/>
                            <div>Dark</div>
                        </div>
                        <div className="nav-dropdown-item nav-bar-style-logout-container">
                            <img
                                alt=""
                                src="icons/settings.svg"
                                className="logout"/>
                            <div>Settings</div>
                        </div>
                        <div className="nav-dropdown-item nav-bar-style-logout-container">
                            <img
                                alt=""
                                src="icons/right.svg"
                                className="logout"
                                onClick={logoutClickHandler}/>
                            <div>Logout</div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </>
    );
}

export default Navigation;