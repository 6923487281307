import {CrudService} from "construct-react";
import cookies from 'js-cookie';

export default class ClientService extends CrudService {

    #domain = process.env.REACT_APP_DOMAIN;
    #url = process.env.REACT_APP_BACKEND_URL;
    #appId = process.env.REACT_APP_APP_ID;
    #clientId = process.env.REACT_APP_CLIENT_ID;


    constructor() {
        super("client");
    }


    async fetchAvailable(appId, onSuccess, onFailure) {
        const options = {
            headers: await this.#createHeader(),
            method: "GET",
        };

        const url = appId
            ? this.#url + "/api/client/fetch-available?appId=" + appId
            : this.#url + "/api/client/fetch-available";

        return fetch(url, options);

    }

    async #createHeader() {
        const ipAddress = await fetch("https://api.ipify.org/?format=json", {
            method: "GET",
        });
        const addrJson = await ipAddress.json();
        const token = cookies.get('prism_token', {domain: this.#domain});
        return {
            'Content-Type': 'application/json',
            'REMOTE_ADDR': addrJson.ip,
            'app-id': this.#appId,
            'client-id': this.#clientId,
            'prism-token': token
        };
    }

}