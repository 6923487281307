import { ServiceRepository } from "construct-react";

export const tableDescriptor = () => {
    return ({
        columns: [
            {
                id: "email",
                label: "Email"
            },
            {
                id: "firstname",
                label: "Firstname"
            },
            {
                id: "lastname",
                label: "Lastname"
            }
        ],
        tableOptions: {
            style: "buttons",
            options: [
                {
                    style: "icon",
                    icon: "icons/add.svg",
                    label: "Add",
                    onSelect:
                    {
                        "id": "newUser",
                        "do": async (items) => {

                        }
                    }
                }
            ]
        },
        rowOptions: {
            style: "row",
            options: [
                {
                    style: "icon",
                    icon: "icons/edit.svg",
                    label: "Edit",
                    onSelect:
                    {
                        "id": "editUser",
                        "do": async (items) => {

                        }
                    }
                },
                {
                    style: "icon",
                    icon: "icons/delete.svg",
                    label: "Delete",
                    onSelect:
                    {
                        "id": "defaultDelete",
                        "do": async (items) =>
                            ServiceRepository.getInstance().getService("user").delete([items[0].id])
                    }
                },
            ]
        }
    });
}

export const formDescriptor = {
    fieldDescriptors: [
        {
            id: "email",
            label: "Email",
            type: "text-input",
            rules: { required: true }
        },
        {
            id: "firstname",
            label: "Firstname",
            type: "text-input",
            rules: { required: true }
        },
        {
            id: "lastname",
            label: "Lastname",
            type: "text-input",
            rules: { required: true }
        },
        {
            id: "apps",
            label: "Apps",
            type: "multi-combo-input",
            rules: {},
            optionProvider: (setOptions) => {
                return ServiceRepository
                    .getInstance()
                    .getService("app")
                    .fetchAll().then(res => {
                        res.json().then(json => {
                            setOptions(json);
                        });

                    }).catch(err => {
                        setOptions([]);
                    });
            },
            valueField: "id",
            optionDisplay: (option) => option.name,
        }
    ]
}