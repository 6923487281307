import { useEffect, useState } from "react";
import { Form, ServiceRepository, Table } from "construct-react";
import AuthView from "../auth/authView";
import "./clientView.css";
import { formDescriptor, tableDescriptor } from "./clientDesc";

function ClientView() {
    const service = ServiceRepository.getInstance().getService("client");
    const [showForm, setShowForm] = useState(false);

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
    });

    const showEditForm = (entity) => {
        setShowForm(!showForm);
        setSelectedEntity(entity);
    }

    const onFormSubmit = (res) => {
        setIsLoaded(false);
    }

    const onFormClose = (res) => {
        setShowForm(false);
        setSelectedEntity(null);
    }

    let listeners = [];
    listeners.push((id, res, items) => {
        if (id === "newClient")
            showEditForm({});

        if (id === "editClient")
            showEditForm(items[0]);
    });

    const tableDesc = tableDescriptor();

    return (
        <>
            <AuthView>
                <div className="client-list-view">
                    <div className="client-table-container">
                        {isLoaded && <Table
                            id="client"
                            load={(args) => service.fetchAll()}
                            descriptor={tableDesc}
                            changeListeners={listeners} />}
                    </div>
                    {showForm &&
                        <div className="client-form-container">
                            <Form
                                label=""
                                entity={selectedEntity}
                                serviceId="client"
                                descriptor={formDescriptor(selectedEntity.id)}
                                submitCallback={onFormSubmit}
                                closeCallback={onFormClose} />
                        </div>}
                </div>
            </AuthView>
        </>
    );
}
export default ClientView;