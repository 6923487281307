import { useEffect, useState } from "react";
import "./sessionView.css";
import { ServiceRepository, Table } from "construct-react";
import AuthView from "../auth/authView";
import { tableDescriptor } from "./sessionDesc";

function SessionView() {
    const service = ServiceRepository.getInstance().getService("sess");
    const tableDesc = tableDescriptor();

    return (
        <>
            <AuthView>
                <div className="session-table-container">
                    <Table id="session"
                        load={(args) => service.fetchAll()}
                        descriptor={tableDesc} />
                </div>
            </AuthView>
        </>
    );

} export default SessionView;