import React from "react";
import './LoginView.css';
import cookies from 'js-cookie';
import AuthService from "../service/AuthService";
import {TextInput} from "construct-react";
import AuthContext from "../context/AuthContext";

export default class LoginView extends React.Component {

    #redirectUri = null;
    #authContext = null;

    constructor() {
        super();

        this.#authContext = AuthContext.getInstance();

        const currentLoc = window.location.href;
        if (!currentLoc.includes('?redirectUri=')) {
            const error = new Error("message");
            error.code = "400";
            throw error;
        }

        this.#redirectUri = this.#extractRedirectUri(currentLoc);
    }

    state = {
        dirty: false,
        email: null,
        password: null
    };

    componentDidMount() {
        if (this.#authContext.isValid())
            window.location.href = this.#redirectUri;
        else
            this.#authContext.removeToken();

    }

    #extractRedirectUri(currentLoc) {
        const uriParts = currentLoc.split('?');
        return uriParts[1].split('=')[1];
    }

    #handleSubmit = (e) => {
        e.preventDefault();
        const authService = new AuthService();
        authService.login(this.state.email, this.state.password,
            result => {
                cookies.set('prism_token', result.data, {domain: process.env.REACT_APP_DOMAIN, expires: 7});
                window.location.href = this.#redirectUri;
            },
            err => {
                console.log(err);
            });
    }

    #handleEmailChange = (value) => {
        this.setState({email: value});
    }

    #handlePasswordChange = (value) => {
        this.setState({password: value});
    }

    #handleValidation = (isValid) => {
        console.log("validation called");
    }

    render() {
        return (
            <div className="login-view-main">
                <div className="login-form-container">
                    <form className="login-form" onSubmit={this.#handleSubmit}>
                        <TextInput label="Email" value={this.state.email} rules={{required: true}}
                                   onValueChange={this.#handleEmailChange} onValidation={this.#handleValidation}/>
                        <TextInput label="Password" value={this.state.password} rules={{required: true}}
                                   onValueChange={this.#handlePasswordChange} onValidation={this.#handleValidation}/>
                        <input className="button primary-button login-form-input" type="submit" value="Login"></input>
                    </form>
                </div>
            </div>
        )
    }
}
