import axios from "axios";

export default class AuthService {
    #url = process.env.REACT_APP_BACKEND_URL;
    #appId = process.env.REACT_APP_APP_ID;
    #clientId = process.env.REACT_APP_CLIENT_ID;

    async login(username, password, onSuccess, onFailure) {
        axios.post(this.#url + "/api/login", {
            username: username,
            password: password
        }, {
            headers: await this.#createHeader()
        }).then(res => onSuccess(res)
        ).catch(err => onFailure(err));
    }

    async logout(token, onSuccess, onFailure) {
        axios.post(this.#url + "/api/logout", {
            token: token
        }, {
            headers: await this.#createHeader()
        }).then(res => onSuccess(res)
        ).catch(err => onFailure(err));
    }

    async validateToken(token) {
        const options = {
            headers: await this.#createHeader(),
            method: "POST",
            body: token
        };

        return fetch(this.#url + "/api/validate", options);
    }

    async refreshToken() {
        //TODO: finish token validation
        return null;
    }

    async #createHeader() {
        const ipAddress = await axios.get("https://api.ipify.org/?format=json");

        return {
            'Content-Type': 'application/json',
            'REMOTE_ADDR': ipAddress.data.ip,
            'app-id': this.#appId,
            'client-id': this.#clientId
        };
    }
}