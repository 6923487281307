import React from "react";
import AuthView from "./auth/authView";

export default class MainView extends React.Component {

    render() {
        return (
            <>
                <AuthView>
                    {process.env.REACT_APP_CLIENT_ID}
                </AuthView>
            </>
        );
    }
}