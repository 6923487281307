export const tableDescriptor = () => {
    return ({
        columns: [
            {
                id: "userEmail",
                label: "Email"
            },
            {
                id: "lastIpAddress",
                label: "Last IP Address"
            },
            {
                id: "created",
                label: "Created at"
            },
            {
                id: "userAgent",
                label: "User Agent"
            }
        ],
        tableOptions: {
            style: "row",
            options: []
        },
        rowOptions: {
            style: "row",
            options: []
        }
    });
}