import { ServiceRepository } from "construct-react";

export const tableDescriptor = () => {
    return ({
        columns: [
            { id: "name", label: "Name" }
        ],
        tableOptions: {
            style: "buttons",
            options: [
                {
                    style: "icon",
                    icon: "icons/add.svg",
                    label: "Add",
                    onSelect:
                    {
                        "id": "newClient",
                        "do": async (items) => {

                        }
                    }
                }
            ]
        },
        rowOptions: {
            style: "row",
            options: [
                {
                    style: "icon",
                    icon: "icons/edit.svg",
                    label: "Edit",
                    onSelect:
                    {
                        "id": "editClient",
                        "do": async (items) => {

                        }
                    }
                },
                {
                    style: "icon",
                    icon: "icons/delete.svg",
                    label: "Delete",
                    onSelect:
                    {
                        "id": "defaultDelete",
                        "do": async (items) =>
                            ServiceRepository.getInstance().getService("client").delete([items[0].id])
                    }
                },
            ]
        }
    });
}

export const formDescriptor = (clientId) => {
    return ({
        fieldDescriptors: [
            {
                id: "id",
                label: "Id",
                type: "label",
                rules: { editable: false }
            },
            {
                id: "name",
                label: "Name",
                type: "text-input",
                rules: { required: true }
            },
            {
                id: "secret",
                label: "Secret",
                type: "text-input",
                rules: { required: true }
            }
        ]
    })
}
