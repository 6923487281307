import cookies from 'js-cookie';

export default class SessionService {

    #domain = process.env.REACT_APP_DOMAIN;
    #url = process.env.REACT_APP_BACKEND_URL;
    #appId = process.env.REACT_APP_APP_ID;
    #clientId = process.env.REACT_APP_CLIENT_ID;

    async fetchAll() {
        const options = {
            headers: await this.#createHeader(),
            method: "GET",
        };
        return fetch(this.#url + "/api/fetch-sessions", options);
    }

    async #createHeader() {
        const ipAddress = await fetch("https://api.ipify.org/?format=json", {
            method: "GET",
        });
        const addrJson = await ipAddress.json();
        const token = cookies.get('prism_token', {domain: this.#domain});
        return {
            'Content-Type': 'application/json',
            'REMOTE_ADDR': addrJson.ip,
            'app-id': this.#appId,
            'client-id': this.#clientId,
            'prism-token': token
        };
    }
}