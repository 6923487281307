import cookies from 'js-cookie';
import AuthService from '../service/AuthService';

/**
 * Singleton class. Do not create new instance directly, call getInstance().
 */
export default class AuthContext {

    #domain = process.env.REACT_APP_DOMAIN;
    #prismToken = null;
    #firstName = null;
    #lastName = null;
    #email = null;
    #expDate = null;

    constructor(prismToken) {
        this.#prismToken = prismToken;

        if (this.#prismToken != null)
            this.#extractTokenDetails();
    }

    #extractTokenDetails() {
        const decoded = this.#decodeToken();
        this.#firstName = decoded.payload.firstname;
        this.#lastName = decoded.payload.lastname;
        this.#email = decoded.payload.email;
        this.#expDate = new Date(1970, 0, 1);
        this.#expDate.setSeconds(decoded.payload.exp);
    }

    /**
     * Decodes the jwt token.
     *
     * @returns the decoded jwt token model.
     */
    #decodeToken() {
        let token = {};
        token.raw = this.#prismToken;
        token.header = JSON.parse(window.atob(this.#prismToken.split('.')[0]));
        token.payload = JSON.parse(window.atob(this.#prismToken.split('.')[1]));
        return (token)
    }

    /**
     * Creates the authentication context. Fails if there is no token.
     *
     * @returns the singleton AuthContext instance.
     */
    static getInstance() {
        const token = cookies.get('prism_token', {domain: this.#domain});
        if (!this.instance)
            this.instance = new AuthContext(token);

        return this.instance;
    }

    loadToken() {
        this.#prismToken = cookies.get('prism_token', {domain: this.#domain});
        if (this.#prismToken)
            this.#extractTokenDetails();
    }

    removeToken() {
        cookies.remove('prism_token', {domain: this.#domain});
        this.#prismToken = null;
        this.#firstName = null;
        this.#lastName = null;
        this.#email = null;
        this.#expDate = null;
    }

    isValid() {
        return this.#prismToken && !this.isExpired();
    }

    isExpired() {
        var nowDate = new Date();
        return nowDate > this.#expDate;
    }

    getToken() {
        return this.#prismToken;
    }

    getFullname() {
        return this.#firstName + " " + this.#lastName;
    }

    getEmail() {
        return this.#email;
    }
}