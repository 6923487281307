import "./authView.css";
import Navigation from "../../components/nav/navigation";
import {useState} from "react";
import AuthService from "../../service/AuthService";
import AuthContext from "../../context/AuthContext";

function AuthView({children}) {
    const redirectUri = process.env.REACT_APP_AUTH_URL + "/login?redirectUri=" + process.env.REACT_APP_REDIRECT_URI;
    const service = new AuthService();
    const authContext = AuthContext.getInstance();

    const [authState, setAuthState] = useState(0);

    authContext.loadToken();

    if (authState === 0) {
        if (authContext.isValid()) {
            service.validateToken(authContext.getToken())
                .then(result => {
                    console.log("Validation res:" + result.data);
                    if (result) {
                        setAuthState(2);
                    } else {
                        authContext.removeToken();
                        setAuthState(1);
                    }
                })
                .catch(err => {
                    authContext.removeToken();
                    setAuthState(1);
                });
        } else {
            setAuthState(1);
        }
    }

    if (authState === 1)
        window.location.href = redirectUri;

    return (
        <>
            <div className="authenticated-container">
                <Navigation/>
                {authState === 2 &&
                    <div>
                        {children}
                    </div>
                }
            </div>
        </>
    );
}

export default AuthView;