import { React, useEffect, useState } from "react";
import { Routes, Route } from 'react-router-dom';
import LoginView from './view/LoginView';
import MainView from './view/MainView';
import UserListView from './view/user/usersView';
import AppView from './view/app/appView';
import LogoutView from './view/LogoutView';
import SessionView from './view/session/sessionView';
import { CrudService, ServiceRepository, AppContext } from "construct-react";
import SessionService from './service/SessionService';
import ClientService from "./service/ClientService";
import ClientView from "./view/client/clientView";

function App() {

  const [isLoaded, setIsLoaded] = useState(false);
  const appContext = AppContext.getInstance(new Map([
    ["breeze-light", () => {
      import("./themes/breeze/light/theme.css").then(res => {
        console.log("Theme imported: breeze-light.");
      })
    }],
    ["breeze-dark", () => {
      import("./themes/breeze/dark/theme.css").then(res => {
        console.log("Theme imported: breeze-dark.");
      })
    }],
  ]));
  const importFunc = appContext.importTheme();


  appContext.setThemeChangeListener((theme, variant) => {
    setIsLoaded(false);
  });

  const serviceRepo = ServiceRepository.getInstance();
  serviceRepo.addService("user", new CrudService("user"));
  serviceRepo.addService("app", new CrudService("app"));
  serviceRepo.addService("client", new ClientService());
  serviceRepo.addService('sess', new SessionService());

  useEffect(() => {
    importFunc();
    setIsLoaded(true);
  });

  return (
    <>
      <div className='main-container'>
        <Routes>
          <Route path="login" element={<LoginView />} />
          <Route path="logout" element={<LogoutView />} />
          <Route path="/" element={<MainView />} />

          <Route path="sessions" element={<SessionView />} />
          <Route path="users" element={<UserListView />} />
          <Route path="apps" element={<AppView />} />
          <Route path="clients" element={<ClientView />} />
        </Routes>
      </div>
    </>
  );
}

export default App;